import * as React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostItemList from "../components/post-item-list"
import * as styles from "../styles/index.module.css"

const FeaturedPost = ({ post }) => (
  <article
    className={styles.featuredPost}
    onClick={() => navigate(post.fields.slug)}
  >
    <div className={styles.featuredPostThumbnail}></div>
    <div className={styles.featuredPostContent}>
      <div>{post.frontmatter.date}</div>
      <Link className={styles.featuredPostTitle} to={post.fields.slug}>
        {post.frontmatter.title}
      </Link>
      <div className={styles.featuredPostSummary}>
        {post.frontmatter.summary}
      </div>
    </div>
  </article>
)

const Hero = <div className={styles.hero} />

const IndexPage = () => {
  const dateFormat = "YYYY MMM DD"
  const data = useStaticQuery(graphql`
    query {
      math: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: { 
            category: { eq: "math" }, 
            featured: { eq: true },
            publish: { eq: true }
          }
        }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY MMMM DD")
              summary
              hideDate
              sublinks {
                path
                text
                nonStandard
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
      blog: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
          frontmatter: { 
            category: { eq: "blog" },
            publish: { eq: true }
          } 
        }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY MMMM DD")
              summary
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
      code: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { in: ["code"] } } }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY MMM DD")
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    // <Layout hero={Hero}>
    <Layout>
      <section className={styles.section}>
        <h1 className={styles.title}>math</h1>
        {/*<FeaturedPost post={data.math.edges[0].node} />*/}
        <PostItemList className="Section" posts={data.math.edges.slice(0).map(edge => edge.node)} >
          <Link to="/math" className={styles.moreLink}>more</Link>
        </PostItemList>
      </section>

      <section className={styles.section}>
        <h1 className={styles.title}>blog</h1>
        {/*<FeaturedPost post={data.blog.edges[0].node} />*/}
        <PostItemList className="Section" posts={data.blog.edges.slice(0).map(edge => edge.node)}>
          <Link to="/blog" className={styles.moreLink}>more</Link>
        </PostItemList>
      </section>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="" description="In mathematics the art of proposing a question must be held of higher value than solving it. – Georg Cantor"/>

export default IndexPage
